<template>
    <el-dialog title="Utilizatori" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :span='12'>
                    <el-form-item label='Asociere angajat' prop="IdAngajat">
                        <el-select v-model='selectedObject.IdAngajat' class='full-width' filterable @change="change_angajat">        
                            <el-option label='Fara' value='-1'></el-option>
                            <el-option v-for='item in Info.angajati' :key="item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='Nume' prop="Nume">
                        <el-input class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='Email' prop="Email">
                        <el-input  class='full-width' v-model='selectedObject.Email' />
                    </el-form-item>
                </el-col>
                <el-col :span='8' v-if="mode=='add'">
                    <el-form-item label='Parola' prop="Parola">
                        <el-input  class='full-width' v-model='selectedObject.Parola' />
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='Grup de drepturi' prop="IdGrupDrepturi">
                        <el-select multiple v-model='selectedObject.IdGrupDrepturi' class='full-width' filterable>        
                            <el-option v-for='item in Info.rights_groups' :key="'rights-groups' + item.Id" :label='item.Name' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span='8'>
                    <el-form-item label='Functie' prop="IdFunctie">
                        <el-select v-model='selectedObject.IdFunctie' class='full-width' filterable>        
                            <el-option v-for='item in Info.functii' :key="'rights-groups' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
                                   
            </el-row>
            <el-row :gutter="15">
                <span style="font-size: 12px; color: red;margin-left: 15px" v-if="Eroare"> {{ MesajEroare }}</span>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings"; 
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Utilizatori_dialog",
        extends: BasePage,
        
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObject: { Nume: '' , Email: '' , Parola: '' , IdGrupDrepturi: [], IdAngajat:'-1' },                                   
                Info:{ rights_groups: [], angajati:[] },   
                Eroare: false,
                MesajEroare: '',                
                rules: {
                    Nume:           [ { required: true, message: 'Campul este obligatoriu' } ],
                    Email:          [ { required: true, message: 'Campul este obligatoriu' } ],
                    Parola:         [ { required: true, message: 'Campul este obligatoriu' } ],
                    IdGrupDrepturi: [ { required: true, message: 'Campul este obligatoriu' } ],
                    IdFunctie:      [ { required: true, message: 'Campul este obligatoriu' } ],
                    IdAngajat:      [ { required: true, message: 'Campul este obligatoriu' } ],
                },
                disabled_nume:false
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup = true;

                if( id == null )
                {
                    this.mode           = "add";
                    this.selectedObject = { Nume: '' , Email: '' , Parola: '' , IdGrupDrepturi: [], IdAngajat:'-1' }                   
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("utilizatori/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                }
            },
            async get_info(){
                var response            = await this.post("utilizatori/get_info_for_dialog" );
                this.Info.rights_groups = response.rights_groups
                this.Info.functii       = response.functii
                this.Info.angajati      = response.angajati
            },
            save: async function() {                
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        let res = await this.post("utilizatori/save", { mode: this.mode, object: this.selectedObject } );
                        console.log(res);
                        if(res.MesajEroare != ''){
                            this.Eroare = true;
                            this.MesajEroare = res.MesajEroare;
                            return;
                        }
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            },
            change_angajat(item){
                this.disabled_nume = false
                if(item != '-1')
                {
                    var filter = this.Info.angajati.filter(a=>{return +a.Id == item})
                    if(filter.length > 0)
                    {
                        this.selectedObject.Nume    = filter[0].Nume
                        this.disabled_nume          = true
                    }
                        
                }
                    
            }
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .border-signature {
      border:2px solid #CECECE;
    }

</style>